import PageComponent from '../../common/component/page-component';

class Timeline extends PageComponent {

	constructor({
		root,
		element,
		activeClass = 'active',
		elementData = 'newsletterElement',
		monthData = 'month',
		yearData = 'year'
	}) {
		super({root: root, element: element});
		this.activeClass = 'active';
		this.elementData = elementData;
		this.monthData = monthData;
		this.yearData = yearData;
	}

	prepare() {
		this.elements = this.element.querySelectorAll(this.dataSelector(this.elementData));
		this.elementsNo = this.elements.length;
		this.checkDate();
	}

	checkDate() {
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;
		const currentYear = currentDate.getFullYear();

		for(let i=0; i<this.elementsNo; i++) {
			const el = this.elements[i];
			const month = parseInt(this.dataAttr(el).get(this.monthData));
			const year = parseInt(this.dataAttr(el).get(this.yearData));
			if(month === currentMonth && year === currentYear) {
				this.classList(el).add(this.activeClass);
				return;
			}
		}
	}
}

export default Timeline;
