import {TweenMax} from 'gsap';
//import offsetTop from '../../common/utils/offsetTop'
import 'gsap/ScrollToPlugin';
import {getViewportHeight} from '../../common/utils/size';
import PageComponent from '../../common/component/page-component';


class ScrollDown extends PageComponent {

	constructor({
		root,
		element,
		duration = 0.7,
		easing = 'Power4.easeOut',
		scrollToAttribute = 'features'
	}) {
		super({root: root, element: element});
		this.defaults.duration = duration;
		this.defaults.easing = easing;
		this.easing = easing;
		this.scrollToAttribute = scrollToAttribute;
	}


	prepare() {
		this.scrollToElement = document.querySelector(this.dataSelector(this.scrollToAttribute));
		if(this.scrollToElement) {
			this.listeners.click = this.events.on(this.element, 'click touchstart', this.onClick.bind(this));
		}
	}


	onClick(event, target) {
		target.blur();
		event.preventDefault();
		TweenMax.to(window, this.defaults.duration, {scrollTo: {y: this.scrollToElement.getBoundingClientRect().top + window.scrollY, autoKill: false}, ease: this.easing});
	}

}

export default ScrollDown;
