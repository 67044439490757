import PageComponent from '../../common/component/page-component';

class CookieInfo extends PageComponent {

	constructor({
		root,
		element,
		closeAttribute = 'closeButton'
	}) {
		super({root: root, element: element});
		this.closeAttribute = closeAttribute;
	}

	prepare() {
		if (document.cookie.split(';').filter((item) => item.includes('cookieInfoClosed=1')).length) {
			this.hideCookieInfo();
		} else {
			this.listeners.over = this.events.on(this.dataSelector(this.closeAttribute), 'click', this.onClose.bind(this));
		}
	}

	hideCookieInfo() {
		this.element.innerHTML = '';
		this.element.style.display = 'none';
	}

	onClose(e) {
		e.preventDefault();
		this.hideCookieInfo();
		document.cookie = `cookieInfoClosed=1`;
	}
}

export default CookieInfo;
