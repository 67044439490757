import Player from '@vimeo/player';
import PageComponent from '../../common/component/page-component';

class Features extends PageComponent {

	constructor({
		root,
		element,
		urlAttribute = 'vimeourl',
		videosContainerAttribute = 'featureVideos',
		videoAttribute = 'featureVideo',
		buttonAttribute = 'featureButton',
		featureIdAttribute = 'featureId',
		graphicAttribute = 'featureGraphic',
		duration = 1.5,
		easing = 'Power4.easeOut',
		activeClass = 'active',
		readyClass = 'ready',
		paddingTop = 250
	}) {
		super({root: root, element: element});
		this.urlAttribute = urlAttribute;
		this.videosContainerAttribute = videosContainerAttribute;
		this.videoAttribute = videoAttribute;
		this.buttonAttribute = buttonAttribute;
		this.featureIdAttribute = featureIdAttribute;
		this.graphicAttribute = graphicAttribute;
		this.duration = duration;
		this.easing = easing;
		this.activeClass = activeClass;
		this.readyClass = readyClass;
		this.paddingTop = paddingTop;
		this.playersNo = 0;
		this.playersInited = 0;
	}

	prepare() {
		this.videosContainer = this.element.querySelector(this.dataSelector(this.videosContainerAttribute));
		this.buttons = this.element.querySelectorAll(this.dataSelector(this.buttonAttribute));
		this.videos = this.element.querySelectorAll(this.dataSelector(this.videoAttribute));
		this.videosNo = this.videos.length;
		this.graphic = this.element.querySelector(this.dataSelector(this.graphicAttribute));
		this.createVideos();

		this.listeners.over = this.events.on(this.dataSelector(this.buttonAttribute), 'mouseover touchstart', this.onSelect.bind(this));
		this.listeners.out = this.events.on(this.dataSelector(this.buttonAttribute), 'mouseout touchend', this.onOut.bind(this));
	}

	onSelect(event, target) {
		const id = this.dataAttr(target).get(this.featureIdAttribute);
		this.showVideo(id, target);

		this.classList(this.element).add(this.activeClass);
	}

	onOut() {
		this.hideVideos();
		this.classList(this.element).remove(this.activeClass);
	}

	createVideos() {
		this.players = [];
		this.initedPlayers = [];
		const options = {
			responsive: true,
			background: true,
			loop: true,
			autoplay: false,
			autopause: true,
			width: '100%',
			height: '100%'
		};
		for(let i=0; i<this.videosNo; i++) {
			options.url = this.dataAttr(this.videos[i]).get(this.urlAttribute);
			const id = this.dataAttr(this.videos[i]).get(this.featureIdAttribute);
			//if no url, then the element is a static image
			if(options.url) {
				const player = new Player(this.videos[i], options);
				this.playersNo++;
				this.players[id] = {};
				this.players[id].player = player;
				player.pause();
				//player.on('play', this.onReady.bind(this, id));
				player.ready().then(this.onReady.bind(this, id));
			}
		}
	}

	onReady(id) {
		if(this.players[id].inited !== true) {
			this.players[id].player.pause();
			this.players[id].inited = true;
			this.playersInited++;
			if(this.playersInited === this.playersNo) {
				this.classList(this.element).add(this.readyClass);
			}
		}
	}

	showVideo(id, button) {
		const video = this.videosContainer.querySelector(this.dataSelector(this.featureIdAttribute, id));
		TweenMax.to(video, this.duration, {opacity: 1, ease: this.easing});
		const player = this.players[id];
		if(player) {
			if(this.currentPlayer && this.currentPlayer !== player.player) this.currentPlayer.pause();
			this.currentPlayer = player.player;
			if(this.currentPlayer.play) {
				this.currentPlayer.play();
			}
		}
		this.hideGraphic();
		this.currentId = id;

		//set position
		TweenMax.to(video, 0, {y: button.getBoundingClientRect().top - this.element.getBoundingClientRect().top - video.getBoundingClientRect().height * 0.4 + button.getBoundingClientRect().height * 0.5});
	}

	hideVideos() {
		for(let i=0; i<this.videosNo; i++) {
			TweenMax.to(this.videos[i], this.duration, {opacity: 0, ease: this.easing});
		}
	}

	hideGraphic() {
		TweenMax.to(this.graphic, this.duration, {opacity: 0, ease: this.easing});
	}
}

export default Features;
