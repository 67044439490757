import Player from '@vimeo/player';
import PageComponent from '../../common/component/page-component';

class PopupVideo extends PageComponent {

	constructor({
		root,
		element,
		urlAttribute = 'vimeourl',
		videoAttribute = 'popupVideo'
	}) {
		super({root: root, element: element});
		this.urlAttribute = urlAttribute;
		this.videoAttribute = videoAttribute;
	}

	prepare() {
		this.videoElement = this.element.querySelector(this.dataSelector(this.videoAttribute));
		this.vimeourl = this.dataAttr(this.videoElement).get(this.urlAttribute);
		const options = {
			url: this.vimeourl,
			//responsive: true,
			background: false,
			loop: false,
			autoplay: false,
			width: '100%',
			height: '100%'
		};

		this.player = new Player(this.videoElement, options);

		this.player.ready().then(this.onReady.bind(this));

		this.listeners.context = this.events.on(this.root, 'context:switchcomplete', this.onContextSwitch.bind(this));
	}

	onContextSwitch(event) {
		if (event.detail.currentContext.getName() === 'modal') {
			this.player.play();
		} else {
			this.player.pause();
		}
	}

	onReady() {
		this.ready = true;
	}
}

export default PopupVideo;
