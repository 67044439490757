import ScrollDown from './scroll-down';
import BlurredButton from './blurred-button';
import HeaderVideo from './header-video';
import LightGraph from './light-graph';

export default (di) => {
	di
		.setType({type: ScrollDown, name: 'ScrollDown', parent: 'PageComponent'})
		.setType({type: BlurredButton, name: 'BlurredButton', parent: 'PageComponent'})
		.setType({type: HeaderVideo, name: 'HeaderVideo', parent: 'PageComponent'})
		.setType({type: LightGraph, name: 'LightGraph', parent: 'PageComponent'})
	;
};
