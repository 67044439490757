import domMixin from '../dom/dom-mixin';
import componentConstants from './_constants';


class PageComponents extends domMixin() {

	// selector = true means that is going to inherit the parent element
	constructor() {
		super();
		this.components = new Map();
		this.root = null;
		this.observer = null;
	}


	injectFactory(factory) {
		this.factory = factory;
	}


	getFactory() {
		return this.factory;
	}


	init(root) {
		this.root = root;
		this.observer = new MutationObserver(this.onDomChange.bind(this));
		this.observer.observe(root, {
			childList: true,
			subtree: true,
			attributes: true,
			attributeFilter: [this.dataAttrParser.getAttributeName(componentConstants.componentAttribute)],
			attributeOldValue: true
		});
		this.processComponents(root, true);
	}


	onDomChange(entries) {
		// console.log('dom change');
		for (let i = 0, end = entries.length; i < end; i++) {
			for (let j = 0, endj = entries[i].removedNodes.length; j < endj; j++) {
				const node = entries[i].removedNodes[j];
				if (node instanceof Element) {
					this.processComponents(node, false);
				}
			}

			for (let j = 0, endj = entries[i].addedNodes.length; j < endj; j++) {
				const node = entries[i].addedNodes[j];
				if (node instanceof Element) {
					this.processComponents(node, true);
				}
			}

			if (entries[i].type === 'attributes') {
				if (entries[i].oldValue !== null && entries[i].oldValue !== '') {
					this.processComponents(entries[i].target, false);
				}
				const newValue = this.dataAttr(entries[i].target).get(componentConstants.componentAttribute, null);
				if (newValue !== null) {
					this.processComponents(entries[i].target, true);
				}
			}
		}
		// console.timeEnd('dom change');
	}


	processComponents(node, adding) {
		const elements = Array.prototype.slice.call(node.querySelectorAll(this.dataSelector(componentConstants.componentAttribute)));
		if (this.dataAttr(node).has(componentConstants.componentAttribute)) {
			elements.unshift(node);
		}

		return (adding ? this.createComponents(elements) : this.removeComponents(elements));
	}



	getRoot() {
		return this.root;
	}


	queryComponents(node, selector, limit = 0) {
		const elements = node.querySelectorAll(selector);
		return this.createComponents(elements, limit);
	}


	queryComponent(node, selector) {
		const components = this.queryComponents(node, selector, 1);
		return (components.length ? components[0] : null);
	}


	getComponents(node, namesOrElements, limit = 0) {
		let elements;
		if (namesOrElements instanceof NodeList) {
			elements = namesOrElements;
		} else if (namesOrElements instanceof Element) {
			elements = [namesOrElements];
		} else {
			if (!Array.isArray(namesOrElements)) {
				namesOrElements = [namesOrElements];
			}
			const selector = namesOrElements.map((name) => (name === '*' ? this.dataSelector(componentConstants.componentAttribute) : this.dataSelector(componentConstants.componentAttribute, name))).join(', ');
			elements = node.querySelectorAll(selector);
		}
		return this.createComponents(elements, limit);
	}


	getComponent(node, namesOrElements) {
		const components = this.getComponents(node, namesOrElements, 1);
		return (components.length ? components[0] : null);
	}


	isComponent(element) {
		return (this.createComponent(element) !== null);
	}


	createComponent(element, init = true) {
		if (this.components.has(element)) {
			return this.components.get(element);
		}
		const name = this.dataAttr(element).get(componentConstants.componentAttribute, null);
		if (name !== null && this.factory.has(name)) {
			const component = this.factory.newInstance(name, {
				root: this.root,
				element: element
			});
			this.components.set(element, component);
			if (init) {
				component.init();
			}
			return component;
		} else {
			console.log('component not found', name);
		}
		return null;
	}


	createComponents(elements, limit = 0, init = true) {
		const components = [];
		for (let i = 0, end = elements.length; i < end; i++) {
			if (limit > 0 && i === limit) {
				break;
			}
			const component = this.createComponent(elements[i], init);
			if (component) {
				components.push(component);
			}
		}
		return components;
	}


	removeComponents(elements) {
		for (let i = 0, end = elements.length; i < end; i++) {
			const element = elements[i];
			if (this.components.has(element)) {
				const component = this.components.get(element);
				if (!component.canSurviveDetached()) {
					component.destroy();
					this.components.delete(element);
				}
			}
		}
	}

}


export default PageComponents;
