import PageComponent from '../../common/component/page-component';
import Paper from 'paper';
import {TweenMax} from 'gsap';
import spline from 'cubic-spline';

class LightGraph extends PageComponent {

	constructor({
		root,
		element,
		canvasAttribute = 'canvas',
		dataAttribute = 'lightData',
		imgAttribute = 'background',
		pointsNo = 72, //180/2.5degrees
		duration = 1
	}) {
		super({root: root, element: element});
		this.canvasAttribute = canvasAttribute;
		this.dataAttribute = dataAttribute;
		this.imgAttribute = imgAttribute;
		this.pointsNo = pointsNo;
		this.duration = duration;

		this.currentLight = 0;
	}


	prepare() {
		this.lightData = JSON.parse(this.dataAttr(this.element).get(this.dataAttribute)).light;
		this.lightsNo = this.lightData.length;

		this.canvas = this.element.querySelector(this.dataSelector(this.canvasAttribute));
		this.paper = Paper.setup(this.canvas);

		this.img = this.element.querySelector(this.dataSelector(this.imgAttribute));

		this.listeners.resize = this.events.on(window, 'resize', this.onResize.bind(this));
		this.onResize();

		this.getGraphAngles();
		this.createPaths();
		this.initPaths();

		this.morphToNext(true);
		this.intervalId = setInterval(this.morphToNext.bind(this), 4000)

		this.observer = new IntersectionObserver(this.onInside.bind(this), {
			threshold: [0]
		});
		this.observer.observe(this.element);
	}

	morphToNext(isForced = false) {
		if(this.inside || isForced) {
			this.currentLight = ++this.currentLight%this.lightsNo;
			this.getLightData();
			this.rebuildLightData();
			this.drawLights();
		}
	}

	getGraphAngles() {
		this.graphAngles = [];
		const angleDiff = 180/this.pointsNo;
		for(let i=0; i<this.pointsNo; i++) {
			this.graphAngles.push(i*angleDiff);
		}
		this.graphAnglesNo = this.graphAngles.length;
	}

	createPaths() {
		this.path0 = this.createPath();
		this.path90 = this.createPath(true);
		this.path180 = this.createPath();
		this.path270 = this.createPath(true);
	}

	initPaths() {
		this.initPath(this.path0, this.graphAnglesNo);
		this.initPath(this.path90, this.graphAnglesNo);
		this.initPath(this.path180, this.graphAnglesNo);
		this.initPath(this.path270, this.graphAnglesNo);
	}

	createPath(altenativeColour = false) {
		const path = new Paper.Path();
		path.strokeColor = '#e8cda3';
		path.strokeWidth = 2;
		if(altenativeColour) {
			path.dashArray = [10, 12];
		}
		path.smooth({ type: 'continuous' });
		return path;
	}


	initPath(path, pointsNo) {
		for(let i=0; i<pointsNo; i++) {
			path.add(this.valueAndAngleToPosition(0, 0));
		}
	}


	drawLights(isWithoutAnim = false) {
		this.resize();
		this.drawLight(this.graphValues0, this.path0, false, isWithoutAnim);
		this.drawLight(this.graphValues90, this.path90, true, isWithoutAnim);
		this.drawLight(this.graphValues180, this.path180, true, isWithoutAnim);
		this.drawLight(this.graphValues270, this.path270, false, isWithoutAnim);
	}


	drawLight(data, path, isReflexion = false, isWithoutAnim = false) {
		for(let i=0; i<this.graphAnglesNo; i++) {
			let angle = Number(this.graphAngles[i]);
			if(isReflexion) angle = 360 - angle;
			const newPoint = this.valueAndAngleToPosition(data[i]/this.maxVal, angle);
			TweenMax.killTweensOf(path.segments[i].point);
			TweenMax.to(path.segments[i].point, isWithoutAnim?0.01:this.duration, {x: newPoint.x, y: newPoint.y, ease: 'Power4.easeOut', delay: isWithoutAnim?0:i*0.01});
		}
	}

	getLightData() {
		this.lightAngles = this.lightData[this.currentLight].angles;
		//if no 0, then use 180, as the light is symetrical
		this.lightValues0 = this.parseArrayToNumbers(this.lightData[this.currentLight].arr0 || this.lightData[this.currentLight].arr180);
		this.lightValues90 = this.parseArrayToNumbers(this.lightData[this.currentLight].arr90);
		this.lightValues180 = this.parseArrayToNumbers(this.lightData[this.currentLight].arr180);
		this.lightValues270 = this.parseArrayToNumbers(this.lightData[this.currentLight].arr270);
		this.lightAnglesNo = this.lightAngles.length;

		this.maxVal = 1.5 * Math.max(...this.lightValues0, ...this.lightValues90, ...this.lightValues180, ...this.lightValues270);
	}

	parseArrayToNumbers(arr) {
		return arr.map(function(item) {
		    return Number(item);
		});
	}

	//use interpolation to rebuild LDT data in a way it always have the same
	//number of points - it allows nice morthing
	rebuildLightData() {
		this.graphValues0 = [];
		this.graphValues90 = [];
		this.graphValues180 = [];
		this.graphValues270 = [];
		for(let i=0; i<this.graphAngles.length; i++) {
			this.graphValues0[i] = spline(this.graphAngles[i], this.lightAngles, this.lightValues0);
			this.graphValues90[i] = spline(this.graphAngles[i], this.lightAngles, this.lightValues90);
			this.graphValues180[i] = spline(this.graphAngles[i], this.lightAngles, this.lightValues180);
			this.graphValues270[i] = spline(this.graphAngles[i], this.lightAngles, this.lightValues270);
		}
	}


	//angle and value to x and y position
	valueAndAngleToPosition(value, angle) {
		const angleRad = (90 - angle) * Math.PI / 180;
		const x = this.radius * value * Math.cos(angleRad) + this.center.x;
		const y = this.radius * value * Math.sin(angleRad) + this.center.x;
		return {x: x, y: y};
	}


	onResize(e) {
		this.drawLights(true);
		setTimeout(this.drawLights.bind(this, true), 1000);
	//	setTimeout(this.resize.bind(this), 1000);
	}

	resize() {
		const width = this.img.getBoundingClientRect().width;
		this.canvas.width = width;
		this.canvas.height = width;
		this.radius = width * 0.5;

	  Paper.view.viewSize = new Paper.Size(width, width);
		this.center = Paper.view.center;
	}


	onInside(entries, observer) {
		for (const entry of entries) {
			const inside = entry.intersectionRatio > 0;
			if (inside !== this.inside) {
				this.inside = inside;
			}
			break;
		}
	}
}

export default LightGraph;
