import Player from '@vimeo/player';
import PageComponent from '../../common/component/page-component';

class HeaderVideo extends PageComponent {

	constructor({
		root,
		element,
		urlAttribute = 'vimeourl',
		duration = 1.5,
		easing = 'Power4.easeOut'
	}) {
		super({root: root, element: element});
		this.urlAttribute = urlAttribute;
		this.duration = duration;
		this.easing = easing;
	}

	prepare() {
		this.vimeourl = this.dataAttr().get(this.urlAttribute);
		const options = {
			url: this.vimeourl,
			responsive: true,
			background: true,
			loop: true,
			autoplay: true,
			width: '100%',
			height: '100%'
		};

		this.player = new Player(this.element, options);

		this.player.ready().then(this.onReady.bind(this));
		//this.player.on('play', this.onReady.bind(this));

		TweenMax.to(this.element, 0, {opacity: 0, force3D: true, ease: this.easing});
	}

	onReady(id) {
		TweenMax.to(this.element, this.duration, {opacity: 1, force3D: true, ease: this.easing});
	}
}

export default HeaderVideo;
