import {TweenMax} from 'gsap';
import PageComponent from '../../common/component/page-component';
import getPointerPosition from '../../common/utils/get-pointer-position';


//TODO: clean this class tidily

class BlurredButton extends PageComponent {

	constructor({
		root,
		element,
		iconAttribute = 'blurredButtonIcon',
		bgAttribute = 'blurredButtonBg',
		bgBlackAttribute = 'blurredButtonBgBlack',
		bgBaseAttribute = 'blurredButtonBgBase',
		animationStrength = 5,
		bgAnimationStrength = 30,
		bgBlackAnimationStrength = 150,
		rotationStrength = 60,
		duration = 3,
		easing = 'Power4.easeOut'
	}) {
		super({root: root, element: element});
		this.iconAttribute = iconAttribute;
		this.bgAttribute = bgAttribute;
		this.animationStrength = animationStrength;
		this.bgAnimationStrength = bgAnimationStrength;
		this.bgBlackAttribute = bgBlackAttribute;
		this.bgBlackAnimationStrength = bgBlackAnimationStrength;
		this.bgBaseAttribute = bgBaseAttribute;
		this.duration = duration;
		this.easing = easing;
		this.rotationStrength = rotationStrength;
	}


	prepare() {
		this.icon = this.element.querySelector(this.dataSelector(this.iconAttribute));
		this.bg = this.element.querySelector(this.dataSelector(this.bgAttribute));
		this.bgBlack = this.element.querySelector(this.dataSelector(this.bgBlackAttribute));
		this.bgBase = this.element.querySelector(this.dataSelector(this.bgBaseAttribute));
		this.listeners.mouseMove = this.events.on(this.element, 'mousemove', this.onMouseMove.bind(this));
		this.listeners.mouseLeave = this.events.on(this.element, 'mouseleave', this.onMouseLeave.bind(this));

		TweenMax.set(this.element, {perspective:500,force3D:true});
	}


	onMouseMove(event, target) {
		event.preventDefault();

		const rect = this.element.getBoundingClientRect();

		const pos = getPointerPosition(event);
		const xDiff = ((pos.x - rect.left)/rect.width - 0.5);
		const yDiff = ((pos.y - rect.top)/rect.height - 0.5);
		const xDiffIcon = -xDiff * this.animationStrength;
		const yDiffIcon = -yDiff * this.animationStrength;
		const xDiffBg = -xDiff * this.bgAnimationStrength;
		const yDiffBg = -yDiff * this.bgAnimationStrength;
		const xDiffBgBlack = xDiff * this.bgBlackAnimationStrength;
		const yDiffBgBlack = yDiff * this.bgBlackAnimationStrength;
		const xDiffBgBase = xDiff * this.rotationStrength;
		const yDiffBgBase = yDiff * this.rotationStrength;
		//TweenMax.to(this.icon, this.duration, {x: xDiffIcon, y: yDiffIcon, force3D: false, ease: this.easing});

		if(this.bg) {
		TweenMax.to(this.bg, this.duration, {x: xDiffBg, y: yDiffBg, force3D: true, ease: this.easing});
	}
		if(this.bgBlack) {
			TweenMax.to(this.bgBlack, this.duration, {x: xDiffBgBlack, y: yDiffBgBlack, force3D: true, ease: this.easing});
	}
		if(this.bgBase) {
				TweenMax.to(this.bgBase, this.duration, {rotationX: -yDiffBgBase, rotationY: xDiffBgBase, perspective: 10, force3D: true, ease: this.easing});
		}
	}

	onMouseLeave(event, target) {
		event.preventDefault();

		//TweenMax.to(this.icon, this.duration, {x: 0, y: 0, force3D: false, ease: this.easing});
		TweenMax.to(this.bg, this.duration, {x: 0, y: 0, force3D: true, ease: this.easing});
			if(this.bgBlack) {
					TweenMax.to(this.bgBlack, this.duration, {x: 0, y: 0, force3D: true, ease: this.easing});
				}
				if(this.bgBase) {
						TweenMax.to(this.bgBase, this.duration, {rotationX: 0, rotationY: 0, perspective: 10, force3D: true, ease: this.easing});
				}
	}

}

export default BlurredButton;
