import 'dayjs/locale/de';

import commonConfig from './common';

import animationConfig from '../animation/_config';
import appConfig from '../app/_config';
//import contentConfig from '../content/_config';
import contextConfig from '../context/_config';
//import galleryConfig from '../gallery/_config';
//import menuConfig from '../menu/_config';
//import sectionConfig from '../section/_config';
import newsletterConfig from '../newsletter/_config';
import timelineConfig from '../timeline/_config';
import featuresConfig from '../features/_config';
import cookieInfoConfig from '../cookieinfo/_config';
import popupVideoConfig from '../popupvideo/_config';


const config = (di) => {
	commonConfig(di);

	animationConfig(di);
	appConfig(di);
	//contentConfig(di);
	contextConfig(di);
	//galleryConfig(di);
	//menuConfig(di);
	//sectionConfig(di);
	newsletterConfig(di);
	timelineConfig(di);
	featuresConfig(di);
	cookieInfoConfig(di);
	popupVideoConfig(di);

	const prefix = 'xio-';
	const classPrefix = 'xio-js-';

	di
		.setValue('dom/dataAttrPrefix', prefix)
		.setValue('dom/classPrefix', classPrefix)
		.setValue('dom/baseClassPrefix', prefix)
	;

	const bodyData = di.get('dom/dataAttrFactory')({element: document.body});
	const baseUrl = bodyData.get('baseUrl');

	di
		.setValue('baseUrl', baseUrl)
		.setParam('HashScroller', 'duration', 0.5)
	;
};


export default config;
