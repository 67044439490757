import PageComponent from '../../common/component/page-component';
import serialize from 'form-serialize';
import httpRequest from 'superagent';

class Newsletter extends PageComponent {

	constructor({
		root,
		element,
		nameContainerAttribute = 'formName',
		emailContainerFieldAttribute = 'formEmail',
		nameFieldAttribute = 'formNameField',
		emailFieldAttribute = 'formEmailField',
		submitAttribute = 'formSubmit',
		contentAttribute = 'formContent',
		thankyouAttribute = 'formThankyou',
		thankyouIconAttribute = 'formThankyouIcon',
		activeClass = 'active'
	}) {
		super({root: root, element: element});
		this.nameContainerAttribute = nameContainerAttribute;
		this.emailContainerFieldAttribute = emailContainerFieldAttribute;
		this.nameFieldAttribute = nameFieldAttribute;
		this.emailFieldAttribute = emailFieldAttribute;
		this.submitAttribute = submitAttribute;
		this.contentAttribute = contentAttribute;
		this.thankyouAttribute = thankyouAttribute;
		this.thankyouIconAttribute = thankyouIconAttribute;
		this.activeClass = 'active'
	}

	prepare() {
		this.nameContainer = this.element.querySelector(this.dataSelector(this.nameContainerAttribute));
		this.emailContainer = this.element.querySelector(this.dataSelector(this.emailContainerFieldAttribute));
		this.nameField = this.element.querySelector(this.dataSelector(this.nameFieldAttribute));
		this.emailField = this.element.querySelector(this.dataSelector(this.emailFieldAttribute));

		this.submitButton = this.element.querySelector(this.dataSelector(this.submitAttribute));

		this.content = this.element.querySelector(this.dataSelector(this.contentAttribute));
		this.thankyou = this.element.querySelector(this.dataSelector(this.thankyouAttribute));
		this.thankyouIcon = this.element.querySelector(this.dataSelector(this.thankyouIconAttribute));

		this.url = this.element.action;

		this.listeners.nameField =  this.events.on(this.nameField, 'keyup', this.onNameChange.bind(this));
		this.listeners.submitButton = this.events.on(this.submitButton, 'click', this.onSubmit.bind(this));
		this.listeners.submit =  this.events.on(this.element, 'submit onsubmit', this.onSubmit.bind(this));
	}

	onNameChange(e) {
		if(this.nameField.value.length) {
			this.showEmail();
		}
	}

	onSubmit(event) {
		event.preventDefault();
		if(this.nameField.value && this.emailField.value) {
			this.doAjaxCall();
		}
		return false;
	}

	doAjaxCall() {
		console.log('submit');
		const params = serialize(this.element);
		console.log(params);
		httpRequest
			.post(this.url)
			.set('X-Requested-With', 'XMLHttpRequest')
			.send(params)
			.then(this.onResponse.bind(this))
		;
	}

	onResponse(response) {
		console.log(response);
		this.showThankYou();
	}

	showEmail() {
		this.classList(this.emailContainer).add(this.activeClass);
		this.emailField.required = true;
	}

	showThankYou() {
		//this.content.style.display = 'none';
		//this.thankyou.style.display = 'block';

		this.nameContainer.style.pointerEvents = 'none';
		this.emailContainer.style.pointerEvents = 'none';

		this.submitButton.style.display = 'none';

		this.thankyouIcon.style.display = 'block';
	}
}

export default Newsletter;
