import XioApp from './xio-app';
import AppLoader from './app-loader';


export default (di) => {
	di
		.setType({
			type: XioApp, name: 'XioApp', parent: 'App', params: {
				requiredStyles: ['default'],
				requiredFonts: [
				]
			}, setters: {
			}, postCreateCalls: [(app) => {
			}]
		})
		.set('app', di.lazyNew('XioApp'))

		.setType({type: AppLoader, name: 'AppLoader', parent: 'PageComponent'})
	;
};
